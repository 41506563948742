import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    mobile: "",
    token: "",
    isSignup: false,
    outlet: ""
}

const cashierSlice = createSlice({
    name: 'cashier',
    initialState,
    reducers: {
        setCashierDetails: (state, action) => {
            state.mobile = action.payload.mobile
            state.token = action.payload.token
            state.outlet = action.payload.outlet
        }
    }
});

export const { setCashierDetails } = cashierSlice.actions;
export default cashierSlice.reducer
import React, { useState } from 'react'
import { Box, Grid, TextField, Typography } from '@mui/material'
import PrimaryButton from '../components/PrimaryButton'
import axios from '../api/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const UserLogin = () => {

    const [userDetails, setUserDetails] = useState({
        name: "",
        phone: "",
        email: ""
    });
    const [errors, setErrors] = useState({
        name: "",
        phone: "",
        email: ""
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors((prev) => ({ ...prev, [name]: "", email: "", phone: "" }));
        setUserDetails((prev) => ({ ...prev, [name]: value }));
    };

    const handleLogin = async () => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                return;
            }

            const response = await axios.post('kiosk-user', userDetails, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response?.data?.status === true) {
                const kioskUser = response?.data?.data?.dbResponse;
                localStorage.setItem("kioskUser", JSON.stringify(kioskUser));
                const message = response?.data?.data?.message;
                toast.success(message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                navigate('/products');
            }

        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    return (
        <Box sx={{ textAlign: 'center', background: "white" }}>
            <Grid container justifyContent='center'>
                <Grid item md={4}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '22px', margin: '21px 0 18px 0' }}>Enter Details</Typography>
                        <label style={{}}>Enter Name</label>
                        <TextField
                            fullWidth
                            name='name'
                            type='text'
                            value={userDetails?.name}
                            sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                            placeholder="Enter Name"
                            variant="outlined"
                            onChange={handleChange}
                        />
                        <p style={{ marginTop: '-1px', fontSize: '12px', color: 'red' }}>{errors.name}</p>
                    </Box>
                    <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
                        <label style={{}}>Enter Email</label>
                        <TextField
                            type='email'
                            fullWidth
                            name='email'
                            sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                            placeholder="Enter Email"
                            variant="outlined"
                            value={userDetails?.email}
                            onChange={handleChange}
                        />
                        <p style={{ marginTop: '-1px', fontSize: '12px', color: 'red' }}>{errors.email}</p>
                    </Box>
                    <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
                        <label style={{}}>Enter Phone Number</label>
                        <TextField
                            type='text'
                            fullWidth
                            name='phone'
                            sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                            placeholder="Enter Phone Number"
                            variant="outlined"
                            value={userDetails?.phone}
                            onChange={handleChange}
                        />
                        <p style={{ marginTop: '-1px', fontSize: '12px', color: 'red' }}>{errors.phone}</p>
                    </Box>
                    <Grid container justifyContent={'center'} style={{ marginTop: '21px' }}>
                        <Grid item sm={12} md={12}>
                            <PrimaryButton title={'Proceed'}
                                onClick={handleLogin}
                                // disabled={btnDisabled}
                                spinner={true}
                                disabledTitle={'Logging In'}
                                style={{
                                    borderRadius: "30px", fontSize: "18px",
                                    width: '100%',
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                    background: '#002f49'
                                }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default UserLogin

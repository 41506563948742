import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    name: "",
    phone: "",
    email: ""
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state.name = action.payload.name
            state.phone = action.payload.phone
            state.email = action.payload.email
        }
    }
});

export const { setUserDetails } = userSlice.actions;
export default userSlice.reducer
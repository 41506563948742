import './App.css';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './AppRoutes';
import CashierLogin from './auth/CashierLogin';
import SideBar from './components/SideBar';
import './sass/main.scss'
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <AppRoutes />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        theme="colored"
        pauseOnHover={false}
      />
    </div>
  );
}

export default App;

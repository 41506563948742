import { Box } from '@mui/material'
import React from 'react'
import NavBar from './components/NavBar'
import { Outlet } from 'react-router-dom'

const AppLayout = () => {
    return (
        <>
            <Box>
                <NavBar />
            </Box>
            <Box sx={{paddingTop: '90px', background: "#f1f3f4"}}>
                {<Outlet />}
            </Box>
        </>
    )
}

export default AppLayout

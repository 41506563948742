import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import axios from "../api/axios";
import { Add, Remove } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PrimaryButton from "../components/PrimaryButton";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

const Products = () => {
    const navigate = useNavigate();
    const [productsData, setProductsData] = useState([]);
    const [productInfo, setProductInfo] = useState({});
    const [totalOfferPrice, setTotalOfferPrice] = useState(0);
    const [totalMrp, setTotalMrp] = useState(0)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedAddons, setSelectedAddons] = useState({});
    const outlet = localStorage.getItem("outlet");
    const [makingTime, setMakingTime] = useState("");
    const [cartItems, setCartItems] = useState([]);
    const [filteredProductData, setFilteredProductData] = useState([]);
    const [categoriesdata, setCategoriesData] = useState([]);
    const [quantity, setQuantity] = useState(productInfo?.quantity || 1);
    const [isOpen, setIsOpen] = useState(false)
    const [selectedTab, setSelectedTab] = useState('ALL');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [clickedCategory, setClickedCategory] = useState(null);
    const [categoriesList, setCategoriesList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [inputText, setInputText] = useState("");

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        filtereByFoodTypeAndSearch(newValue)
        setSelectedCategory(null)
    };

    const handleCategoryClick = (categoryId) => {
        setClickedCategory((prevClickedCategory) => {
            if (prevClickedCategory === categoryId) {
                // Clicked the same category again, reset state
                setSelectedCategory(null);
                setSelectedTab('ALL')
                setInputText("")
                getCategoriesWithProducts()
                return null;
            } else {
                // Clicked a new category, apply filter
                setSelectedCategory(categoryId);
                const categories = categoriesList.filter((item) => item?._id === categoryId)
                const prods = categories.map((item) => item.products).flat()
                setProductsList(prods)
                setSelectedTab('ALL')
                setInputText("")
                return categoryId;
            }
        });
    };

    const applyFilters = (foodType, categoryId) => {
        let filteredProds = productsData;

        if (foodType !== 'ALL') {
            filteredProds = filteredProds.filter((product) => product.foodType === foodType);
        }

        if (categoryId) {
            filteredProds = filteredProds.filter((product) => product.category?._id === categoryId);
        }

        setFilteredProductData(filteredProds);
    };

    const toggleDrawer = (open) => () => {
        setIsDrawerOpen(open);
        setQuantity(1)
        setIsOpen(false)
    };

    const handleClickOpen = (productId) => {
        const product = productsData.find((item) => item._id === productId);
        if (!product) return;

        setProductInfo(product);

        // Initialize selectedAddons with default addons
        const initialAddons = {};
        let initialTotalPrice = product.offerPrice || 0;
        let initialTotalMrp = product.mrp || 0

        product.addons?.forEach((addon) => {
            addon.addonValues?.forEach((item) => {
                if (item.isDefault) {
                    initialAddons[addon._id] = {
                        _id: addon._id,
                        addonValueId: item._id,
                        price: item.offerPrice || 0,
                        selectedValue: item.value,
                        mrp: item?.mrp ? item?.mrp : 0
                    };
                    initialTotalPrice += item.offerPrice || 0;
                    initialTotalMrp += item?.mrp != null ? item?.mrp : 0
                }
            });
        });

        setSelectedAddons(initialAddons);
        setTotalOfferPrice(initialTotalPrice);
        setTotalMrp(initialTotalMrp)
        toggleDrawer(true)();
    };

    const getProducts = async () => {
        try {
            const response = await axios.get(`product/List`);
            if (response?.status === 200) {

                let products = response?.data?.data?.products
                const updatedProducts = products.map((product) => ({
                    ...product,
                    quantity: 1,
                }));

                setProductsData(updatedProducts);
                setFilteredProductData(updatedProducts)
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getCategories = async () => {
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(`masters/category/get`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.status === 200) {

                const categories = response?.data?.data?.categories
                setCategoriesData(categories)
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const navigateToOrderPayment = () => {
        navigate("/order-payment");
    };

    const getFoodType = (foodType) => {
        switch (foodType) {
            case "VEG":
                return "/assets/VegImagee.png";
            case "NON-VEG":
                return "/assets/NonVeg.png";
            case "VEGAN":
                return "/assets/Vegan.png";
            case "VEG/VEGAN":
                return ["/assets/VegImagee.png", "/assets/Vegan.png"];
            default:
                return "https://example.com/images/unknown.png";
        }
    };

    const handleAddonClick = (
        addonId,
        addonValueId,
        addonPrice = 0,
        addonName,
        addonMrp = 0
    ) => {
        setSelectedAddons((prevSelectedAddons) => {
            const newSelectedAddons = { ...prevSelectedAddons };

            if (
                newSelectedAddons[addonId] &&
                newSelectedAddons[addonId].addonValueId === addonValueId
            ) {
                const currentPrice = newSelectedAddons[addonId].price || 0;
                const currentMrp = newSelectedAddons[addonId].mrp || 0;

                setTotalOfferPrice((prevTotalPrice) => prevTotalPrice - currentPrice);
                setTotalMrp((prevTotalMrp) => prevTotalMrp - currentMrp);
                delete newSelectedAddons[addonId];
            } else {
                if (newSelectedAddons[addonId]) {
                    const currentPrice = newSelectedAddons[addonId].price || 0;
                    const currentMrp = newSelectedAddons[addonId].mrp || 0;

                    setTotalOfferPrice((prevTotalPrice) => prevTotalPrice - currentPrice);
                    setTotalMrp((prevTotalMrp) => prevTotalMrp - currentMrp);
                }
                newSelectedAddons[addonId] = {
                    _id: addonId, // Addon ID
                    addonValueId: addonValueId,
                    price: addonPrice,
                    selectedValue: addonName,
                    mrp: addonMrp || 0,
                };

                setTotalOfferPrice((prevTotalPrice) => prevTotalPrice + (addonPrice || 0));
                setTotalMrp((prevTotalMrp) => prevTotalMrp + (addonMrp || 0));
            }

            return newSelectedAddons;
        });
    };

    const preparePayloadWithAddons = (productData, selectedAddons = {}, newQuantity) => {
        const productId = productData._id;
        let quantity = Number(productData.quantity) || 0;

        newQuantity = Number(newQuantity) || 0;

        // Add the newQuantity to the existing quantity
        quantity += newQuantity;

        let addons = [];
        if (selectedAddons && Object.keys(selectedAddons).length > 0) {
            addons = Object.values(selectedAddons).map((addon) => ({
                _id: addon._id,
                selectedValue: addon.selectedValue,
                addonValueId: addon.addonValueId,
                price: addon.price,
            }));
        }

        // Build payload with conditionally included addons
        const cartItem = {
            product: productId,
            quantity: quantity,
        };

        // Conditionally add addons to cartItem if they are present
        if (addons.length > 0) {
            cartItem.addons = addons;
        }

        const payload = {
            ordersType: "PICKUP",
            outlet: outlet,
            cartItems: [cartItem],
        };

        return payload;
    };

    const preparePayloadWithUpdatedQuantity = (productData, selectedAddons, updatedQuantity) => {
        const productId = productData._id;
        const quantity = Number(updatedQuantity) || 0;

        let addons = [];
        if (selectedAddons && Object.keys(selectedAddons).length > 0) {
            addons = Object.values(selectedAddons).map((addon) => ({
                _id: addon._id,
                selectedValue: addon.selectedValue,
                addonValueId: addon.addonValueId,
                price: addon.price,
                mrp: addon.mrp // Include mrp field here
            }));
        }

        const cartItem = {
            product: productId,
            quantity: quantity,
        };

        if (addons.length > 0) {
            cartItem.addons = addons;
        }

        const payload = {
            ordersType: "PICKUP",
            outlet: outlet,
            cartItems: [cartItem],
        };

        setIsOpen(true)
        return payload
    };

    const handleAddProductToCartWithAddons = async (productData) => {
        try {
            const token = localStorage.getItem("authToken");
            const product = productsData.find(
                (item) => item._id === productData?._id
            );

            if (!product) {
                return;
            }

            product.quantity = 1;

            let payload

            if (isOpen) {
                payload = preparePayloadWithUpdatedQuantity(productInfo, selectedAddons, quantity)
            } else {
                payload = preparePayloadWithAddons(product, selectedAddons);
            }

            // Make API call to add product to cart
            const response = await axios.post("cart", payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.status === 200) {
                const message = response?.data?.data?.message;
                const cartId = response?.data?.data?.cart?._id;
                localStorage.setItem("cartId", cartId);

                toast.success(message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                setIsDrawerOpen(false);
                getCartItems();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getCartItems = async () => {
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(`cart/userCart/${outlet}/PICKUP`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.data?.status === true) {
                const cartItems = response?.data?.data?.cartItems

                let items = cartItems.filter((item) => item?.product?.isDelete === false)

                setCartItems(items);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const createOrder = async () => {
        try {
            const token = localStorage.getItem("authToken");

            const payload = {
                ordersType: "PICKUP",
                paymentMode: "ONLINE",
                outlet: outlet,
                readyTime: makingTime,
            };

            const response = await axios.post("order/create", payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.status === 201) {
                localStorage.setItem("orderId", response?.data?.data?.order?.id);
                localStorage.setItem(
                    "orderAmount",
                    response?.data?.data?.order?.amount
                );
                navigateToOrderPayment();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const deleteCartItem = async (id) => {
        try {
            const token = localStorage.getItem("authToken");

            const payload = {
                cartItemIds: [id],
            };

            const response = await axios.delete("cart", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: payload,
            });

            if (response?.status === 200) {
                // Directly update the cartItems state to remove the deleted item
                setCartItems(prevItems => prevItems.filter(item => item._id !== id));
                const message = response?.data?.data?.message;
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleQuantityChange = async (productId, newQuantity) => {
        try {
            if (newQuantity === 0) {
                deleteCartItem(productId)
                return
            }

            const token = localStorage.getItem("authToken");

            const updatedCartItems = updateCartItems(productId, newQuantity);

            const payload = {
                cartItems: updatedCartItems,
            };

            const response = await axios.put("cart", payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.status === 200) {
                getCartItems();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const updateCartItems = (productId, newQuantity) => {
        const updatedItems = cartItems.map((item) =>
            item._id === productId ? { _id: productId, quantity: newQuantity } : item
        );

        if (!updatedItems.find((item) => item._id === productId)) {
            updatedItems.push({ _id: productId, quantity: newQuantity });
        }

        setCartItems(updatedItems);
        return updatedItems;
    };

    function calculateDiscountPercent(mrp, offerPrice) {
        if (mrp <= 0) {
            return 0;
        }

        const discount = ((mrp - offerPrice) / mrp) * 100;
        return Math.round(discount * 100) / 100;
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputText(value);
        if (selectedTab === 'VEG' || selectedTab === 'NON-VEG') {
            filtereByFoodTypeAndSearch(selectedTab, value);
        } else {
            filtereByFoodTypeAndSearch(null, value);
        }
    };

    const handleChangeQuantity = (change) => {
        const updatedQuantity = quantity + change;

        // Prevent quantity from being 0 or less
        if (updatedQuantity < 1) {
            return;
        }

        handleProdQtyChange(change, productInfo, setProductInfo);
        setQuantity(updatedQuantity);
        // Prepare payload with updated quantity
        const payload = preparePayloadWithUpdatedQuantity(productInfo, selectedAddons, updatedQuantity);
        // Use the payload as needed
    };

    const handleProdQtyChange = (change, productInfo, setProductInfo) => {
        setProductInfo(prevInfo => ({
            ...prevInfo,
            quantity: Math.max(prevInfo.quantity + change, 0)
        }));
    };

    const getCategoriesWithProducts = async () => {
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(`masters/category/getCategoryWithProducts/${outlet}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.status === 200) {
                const categories = response?.data?.data?.categories
                setCategoriesList(categories);

                const products = categories.map((cat) => cat.products).flat();
                setProductsList(products);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    }

    const filtereByFoodTypeAndSearch = async (foodType, text) => {
        try {
            const token = localStorage.getItem("authToken");

            if (foodType === 'ALL') {
                const response = await axios.get(`masters/category/getCategoryWithProducts/${outlet}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response?.status === 200) {
                    const categories = response?.data?.data?.categories
                    setCategoriesList(categories);

                    const products = categories.map((cat) => cat.products).flat();
                    setProductsList(products);
                }
                return
            }

            let url = `masters/category/getCategoryWithProducts/${outlet}`;

            const params = [];
            if (foodType) {
                params.push(`foodType=${foodType}`);
            }
            if (text) {
                params.push(`search=${text}`);
            }

            if (params.length > 0) {
                url += `?${params.join("&")}`;
            }

            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.status === 200) {
                const categories = response?.data?.data?.categories
                const products = categories.map((cat) => cat.products).flat();
                setProductsList(products);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    }

    useEffect(() => {
        if (!productInfo) return;

        const basePrice = productInfo.offerPrice || 0;
        const addonsPrice = Object.values(selectedAddons).reduce(
            (total, addon) => total + addon.price,
            0
        );
        productInfo.quantity = 1;
        setTotalOfferPrice(basePrice + addonsPrice);
    }, [selectedAddons, productInfo]);

    useEffect(() => {
        getProducts();
        getCategories()
        getCartItems();
        if (inputText === "" || selectedTab === "ALL") {
            getCategoriesWithProducts();
        }
        setSelectedTab('ALL');
        setSelectedCategory(null)
    }, []);

    return (
        <Box
            sx={{
                height: "100vh",
            }}
        >
            <Box sx={{
                display: "flex",
                overflowY: "scroll",
                height: "100%",
                background: "#f1f3f4"
            }}>

                <Stack direction="row" spacing={"1%"} pl={1}>
                    <Box sx={{ width: '60%', minWidth: '614px' }} p={2}>
                        <Box mb={1} sx={{
                            width: '100%',
                            overflowY: 'auto',
                            bgcolor: '#f0f0f0',
                        }}
                        >
                            <FormControl sx={{ m: 1, width: '95%' }}>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>}
                                    onChange={handleInputChange}
                                    placeholder="Search Products by name"
                                    sx={{ background: 'white' }}
                                />
                            </FormControl>
                        </Box>

                        <Tabs
                            // centered
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant="fullWidth"
                            mb={2}
                            sx={{
                                '& .Mui-selected': {
                                    color: '#002f49',
                                },
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#002f49',
                                    height: '2px',
                                },
                                maxHeight: '15px',
                                marginBottom: '10px',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '8px',
                            }}
                        >
                            <Tab
                                label="VEG"
                                value="VEG"
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    color: selectedTab === 'VEG' ? '#002f49' : '#002f49',
                                    '&:hover': {
                                        color: '#002f49',
                                    },
                                    minWidth: '100px',
                                    maxHeight: '15px',
                                    fontSize: '14px',
                                    borderRadius: '8px', // Rounded corners for each Tab
                                    backgroundColor: selectedTab === 'VEG' ? '#e1f5fe' : 'white'
                                }}
                            />
                            <Tab
                                label="NON-VEG"
                                value="NON-VEG"
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    color: selectedTab === 'NON-VEG' ? '#002f49' : '#002f49',
                                    '&:hover': {
                                        color: '#002f49',
                                    },
                                    minWidth: '100px',
                                    maxHeight: '15px',
                                    fontSize: '14px',
                                    borderRadius: '8px',
                                    backgroundColor: selectedTab === 'NON-VEG' ? '#e1f5fe' : 'white',
                                }}
                            />
                            <Tab
                                label="ALL"
                                value="ALL"
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    color: selectedTab === 'ALL' ? '#002f49' : '#002f49',
                                    '&:hover': {
                                        color: '#002f49',
                                    },
                                    minWidth: '100px',
                                    maxHeight: '15px',
                                    fontSize: '14px',
                                    borderRadius: '8px',
                                    backgroundColor: selectedTab === 'ALL' ? '#e1f5fe' : 'white',
                                }}
                            />
                        </Tabs>
                        <Box mb={2}>
                            <Grid container spacing={1} justifyContent={'center'} rowSpacing={1}>
                                {categoriesList?.map((category) => (
                                    <Grid item key={category._id}>
                                        <CategoryFilterChip
                                            filterText={category.name}
                                            isSelected={selectedCategory === category._id}
                                            onClick={() => handleCategoryClick(category._id)}
                                            clickable={category.isClickable}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                        {
                            productsList && productsList.length > 0 ? <Grid container spacing={2} pl={2} pt={2}>
                                {productsList?.map((product) => (
                                    <Grid key={product._id} item xs={12} sm={6} md={4} >
                                        <Card
                                            className="shadowed-box"
                                            sx={{
                                                maxWidth: 200,
                                                padding: 1,
                                                borderRadius: 2,
                                                cursor: 'pointer',
                                                opacity: product?.isExhausted || product?.isDelete ? 0.8 : 1,
                                                backgroundColor: product?.isExhausted ? '#f0f0f0' : 'white',
                                                position: 'relative'
                                            }}
                                            onClick={() => (!product?.isExhausted) && handleClickOpen(product?._id)}
                                        >
                                            {(product?.isExhausted || product?.isDelete) && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                                        zIndex: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: '#ff0000',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Out of Stock
                                                </Box>
                                            )}
                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                <Box sx={{ width: '70%' }} >
                                                    <Stack
                                                        direction={"row"}
                                                        alignItems={'center'}
                                                        ml={1}
                                                        mr={1}
                                                        mb={1}
                                                    >
                                                        <Box >
                                                            {Array.isArray(getFoodType(product?.foodType)) ? (
                                                                getFoodType(product?.foodType).map((url, index) => (
                                                                    <img
                                                                        key={index}
                                                                        src={url}
                                                                        alt={`Food type image ${index}`}
                                                                        width={12}
                                                                        style={{ marginRight: 4 }}
                                                                    />
                                                                ))
                                                            ) : (
                                                                <img
                                                                    src={getFoodType(product?.foodType)}
                                                                    alt="Food type"
                                                                    width={12}
                                                                />
                                                            )}
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                color: "#46546c",
                                                                padding: "4px 8px",
                                                                fontSize: "0.6rem",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                display: "block",
                                                            }}
                                                        >
                                                            <i> {product?.productTag?.[0]?.tagName ? product?.productTag?.[0]?.tagName : null}</i>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: "primary.main",
                                                            fontWeight: "medium",
                                                            padding: "1px 4px",
                                                            fontSize: '9px',
                                                            border: (theme) =>
                                                                `1px solid ${theme.palette.primary.main}`,
                                                            borderRadius: "2px",
                                                            textAlign: "left",
                                                            marginTop: '4px'
                                                        }}
                                                    >
                                                        {Math.ceil(product?.offerPercentage ?? 0)}% off
                                                    </Typography>
                                                </Box>
                                            </Stack>

                                            <CardMedia
                                                component="img"
                                                image={product?.displayImageUrl}
                                                alt="product-img"
                                                sx={{
                                                    width: "60%",
                                                    height: "60%",
                                                    objectFit: "cover",
                                                    margin: "auto",
                                                    cursor: "pointer",
                                                }}
                                            />
                                            <CardContent>
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: 13,
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        display: "block",
                                                        width: "100%",
                                                        textAlign: 'left'
                                                    }}
                                                    gutterBottom
                                                >
                                                    {product.name}
                                                </Typography>
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: 11,
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        display: "block",
                                                        width: "100%",
                                                        textAlign: 'left',
                                                    }}
                                                    gutterBottom
                                                >
                                                    <i>{product?.category?.name}</i>
                                                </Typography>
                                                <Stack mt={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                        <Typography variant="body2" sx={{ color: "#bcbcbd" }}>
                                                            <span>₹ </span>{" "}
                                                            <span style={{ textDecoration: "line-through", fontSize: 12, }}>
                                                                {product.mrp?.toFixed(2)}
                                                            </span>
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            sx={{ fontWeight: "bold", color: "#002f49", fontSize: 14, }}
                                                        >
                                                            ₹{product.offerPrice}
                                                        </Typography>

                                                    </Box>

                                                    <Typography
                                                        variant="body2"
                                                        sx={{ fontWeight: "bold", color: "text.secondary", fontSize: 10, marginTop: '5px', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <span><StarIcon sx={{ color: "gold", fontSize: 15 }} /></span> <span style={{ marginTop: '-2px' }}>4.2</span>
                                                    </Typography>
                                                </Stack>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid> : <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ marginTop: '80px' }}>
                                <Box>No Products Found</Box>
                            </Stack>
                        }


                    </Box>

                    {cartItems && cartItems.length > 0 ? (
                        <Box
                            sx={{
                                width: '40%',
                                position: 'fixed',
                                right: 0,
                                top: 90,
                                height: '100vh',
                                bgcolor: 'white',
                                p: 2,
                            }}
                        >
                            <Box>
                                {cartItems?.length > 0 && (
                                    <Grid container justifyContent="space-between" sx={{ marginBottom: "20px" }}>
                                        <Grid
                                            item
                                            md={12}
                                            sx={{
                                                borderRadius: "10px",
                                                padding: "15px",
                                                backgroundColor: "#fff",
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                m={0}
                                                sx={{ fontWeight: "bold", color: "#333", marginBottom: "10px" }}
                                            >
                                                Your Cart Items
                                            </Typography>
                                            <Box
                                                sx={{
                                                    maxHeight: 'calc(100vh - 150px)',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                {cartItems?.map((product, index) => (
                                                    <React.Fragment key={product?._id}>
                                                        <Card>
                                                            <Grid container justifyContent="space-between" p={1}>
                                                                <Grid
                                                                    item
                                                                    md={12}
                                                                    sx={{
                                                                        borderRadius: "4px",
                                                                        padding: "4px",
                                                                    }}
                                                                >
                                                                    <Grid container>
                                                                        <Grid
                                                                            item
                                                                            md={11}
                                                                            sx={{ display: "flex", alignItems: "center" }}
                                                                        >
                                                                            <Typography
                                                                                variant="h6"
                                                                                fontWeight="bold"
                                                                                className="onlineOrderFont"
                                                                            >
                                                                                {product?.product?.name} - ₹
                                                                                {(
                                                                                    (product?.rate || 0) * (product?.quantity || 1) +
                                                                                    product?.addons?.reduce(
                                                                                        (acc, item) => acc + (item?.offerPrice || 0),
                                                                                        0
                                                                                    ) * (product?.quantity || 1)
                                                                                ).toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container>
                                                                        {product?.addons &&
                                                                            product?.addons.map((item, i) => (
                                                                                <Grid item key={i} className="attributes">
                                                                                    {item?.selectedValue}
                                                                                </Grid>
                                                                            ))}
                                                                    </Grid>
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent={"space-between"}
                                                                        sx={{ padding: "20px 0 10px 0", alignItems: "center" }}
                                                                    >
                                                                        <CancelIcon
                                                                            sx={{ color: "#bcbcbd", fontSize: 20 }}
                                                                            className="cursor-p"
                                                                            onClick={() => deleteCartItem(product?._id)}
                                                                        />
                                                                        <Box>
                                                                            <Stack direction={"row"} spacing={1}>
                                                                                <Remove
                                                                                    onClick={() =>
                                                                                        handleQuantityChange(
                                                                                            product?._id,
                                                                                            product?.quantity - 1
                                                                                        )
                                                                                    }
                                                                                    className="cursor-p"
                                                                                    style={{
                                                                                        fontSize: "2rem",
                                                                                        color: "#fff",
                                                                                        backgroundColor: "#002f49",
                                                                                        borderRadius: "50px",
                                                                                    }}
                                                                                />
                                                                                <Stack
                                                                                    direction={"row"}
                                                                                    alignItems={"center"}
                                                                                    justifyContent={"center"}
                                                                                    sx={{
                                                                                        width: "30px",
                                                                                        textAlign: "center",
                                                                                    }}
                                                                                >
                                                                                    {product?.quantity}
                                                                                </Stack>
                                                                                <Add
                                                                                    onClick={() =>
                                                                                        handleQuantityChange(
                                                                                            product?._id,
                                                                                            product?.quantity + 1
                                                                                        )
                                                                                    }
                                                                                    className="cursor-p"
                                                                                    style={{
                                                                                        fontSize: "2rem",
                                                                                        color: "#fff",
                                                                                        backgroundColor: "#002f49",
                                                                                        borderRadius: "50px",
                                                                                    }}
                                                                                />
                                                                            </Stack>
                                                                        </Box>
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                        {cartItems.length > 1 && index < cartItems.length - 1 && (
                                                            <Divider sx={{ margin: "10px 0" }} />
                                                        )}
                                                    </React.Fragment>
                                                ))}

                                                <PrimaryButton
                                                    title={"Proceed"}
                                                    onClick={createOrder}
                                                    spinner={true}
                                                    style={{
                                                        borderRadius: "30px", fontSize: "18px",
                                                        width: '90%',
                                                        marginLeft: '10%',
                                                        marginTop: '10px',
                                                        marginBottom: '20px',
                                                        background: '#002f49'
                                                    }}
                                                />
                                            </Box>

                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                backgroundColor: "#f9f9f9",
                                // borderRadius: "12px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                overflow: "hidden",
                                height: '100vh',
                                bgcolor: 'white',
                                width: '40%',
                                position: 'fixed',
                                right: 0,
                                top: 90,
                                height: '100vh',
                                bgcolor: 'white',
                            }}
                        >
                            <img
                                src="/assets/empty-cart.png"
                                width={"60%"}
                                style={{ marginBottom: "16px" }}
                            />
                            <Typography variant="h6" sx={{ color: "#555" }}>
                                Your cart is currently empty
                            </Typography>
                        </Box>
                    )}
                </Stack>
                <Drawer
                    anchor="bottom"
                    open={isDrawerOpen}
                    onClose={toggleDrawer(false)}
                    PaperProps={{
                        sx: {
                            maxWidth: "430px",
                            margin: "auto",
                            borderTopLeftRadius: "16px",
                            borderTopRightRadius: "16px",
                            padding: 3,
                            boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                            display: "flex",
                            flexDirection: "column",
                        },
                    }}
                >
                    {/* Fixed Top Section */}
                    <Box>
                        <Stack direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                            {/* Product Image */}
                            <Box sx={{ width: "100px", height: "100px", flexShrink: 0 }}>
                                <img
                                    src={productInfo?.displayImage}
                                    alt="product-image"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                    }}
                                />
                            </Box>

                            {/* Product Details */}
                            <Box>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width={"100%"}
                                >
                                    <Box mb={1} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        {Array.isArray(getFoodType(productInfo?.foodType)) ? (
                                            getFoodType(productInfo?.foodType).map((url, index) => (
                                                <img
                                                    key={index}
                                                    src={url}
                                                    alt={`Food type image ${index}`}
                                                    width={18}
                                                    style={{ marginRight: 4 }}
                                                />
                                            ))
                                        ) : (
                                            <img
                                                src={getFoodType(productInfo?.foodType)}
                                                alt="Food type"
                                                width={18}
                                            />
                                        )}
                                    </Box>
                                    <Box>
                                        <IconButton
                                            onClick={toggleDrawer(false)}
                                            sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 16,
                                                color: "#222124",
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Stack>
                                <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold", lineHeight: 1, fontSize: '15px' }}
                                >
                                    {productInfo?.name}
                                </Typography>
                                <Typography variant="body2" sx={{ color: "text.secondary", fontSize: '11px' }}>
                                    {productInfo?.description}
                                </Typography>
                            </Box>

                        </Stack>
                    </Box>

                    {/* Scrollable Content */}
                    {productInfo?.addons && productInfo.addons.length > 0 && (
                        <Box sx={{ flex: 1, overflowY: "auto", paddingY: 2 }}>
                            {/* Addons Section */}
                            <Box>
                                {productInfo.addons.map((addon, index) => (
                                    <Box key={addon._id} sx={{ marginBottom: 3 }}>
                                        <Stack direction="row" spacing={1} mt={3} justifyContent={'space-between'}>
                                            <Box>
                                                <Typography
                                                    variant="h6"
                                                    sx={{ fontWeight: "bold", width: "15%", fontSize: '15px', marginRight: '10px', marginTop: '10px' }}
                                                >
                                                    {addon.addonTitle}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Stack direction="row" spacing={2} flexWrap="wrap" sx={{ marginRight: '20px' }}>
                                                    {addon.addonValues.map((item) => {
                                                        const isSizeAddon = addon.addonTitle === 'Size';
                                                        const isSelected = selectedAddons[addon._id]?.addonValueId === item._id;

                                                        let imgSrc = `/assets/${item.value}-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                        let imgStyle = { width: '50px', height: '50px' };
                                                        if (item.value === "Medium") {
                                                            imgSrc = `/assets/medium-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                            imgStyle = { width: '22px', height: '40px', margin: '2px 15px 0 15px' };
                                                        } else if (item.value === "Large") {
                                                            imgSrc = `/assets/large-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                            imgStyle = { width: '25px', height: '45px', margin: 'px 15px 0 15px' };
                                                        } else if (item.value === "Small" || item.value === "Regular") {
                                                            imgSrc = `/assets/small-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                            imgStyle = { width: '20px', height: '30px', margin: '10px 15px 0 15px' };
                                                        }

                                                        return (
                                                            <Stack
                                                                key={item._id}
                                                                direction="column"
                                                                alignItems="center"
                                                                spacing={0.5}
                                                            >
                                                                {isSizeAddon ? (
                                                                    <>
                                                                        {/* Row for images */}
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={2}
                                                                            alignItems="center"
                                                                            sx={{ marginBottom: 1 }}
                                                                        >
                                                                            <img
                                                                                onClick={() =>
                                                                                    handleAddonClick(
                                                                                        addon._id,
                                                                                        item._id,
                                                                                        item.offerPrice || 0,
                                                                                        item.value,
                                                                                        item?.mrp !== undefined && item?.mrp !== null ? item.mrp : 0
                                                                                    )
                                                                                }
                                                                                src={imgSrc}
                                                                                alt={item.value}
                                                                                style={imgStyle}
                                                                            />
                                                                        </Stack>

                                                                        {/* Row for offer prices */}

                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={1}
                                                                            alignItems="center"
                                                                        >
                                                                            <Typography
                                                                                variant="caption"
                                                                                sx={{ color: "success.main", fontWeight: "bold" }}
                                                                            >
                                                                                {`+₹${item.offerPrice ? item.offerPrice : 0}`}
                                                                            </Typography>
                                                                        </Stack>

                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {/* Button for non-size addons */}
                                                                        <Button
                                                                            variant={isSelected ? "contained" : "outlined"}
                                                                            onClick={() =>
                                                                                handleAddonClick(
                                                                                    addon._id,
                                                                                    item._id,
                                                                                    item.offerPrice || 0,
                                                                                    item.value,
                                                                                    item?.mrp !== undefined && item?.mrp !== null ? item.mrp : 0
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                textTransform: "none",
                                                                                margin: "4px",
                                                                                fontSize: "0.81rem",
                                                                                borderRadius: "20px",
                                                                                padding: "1px 16px",
                                                                                backgroundColor: isSelected ? "#002f49" : "#bfbfbf",
                                                                                color: isSelected ? "#fff" : "inherit",
                                                                                borderColor: isSelected ? "transparent" : "#bfbfbf",
                                                                                "&:hover": {
                                                                                    backgroundColor: isSelected ? "#002f49" : "#bfbfbf",
                                                                                    borderColor: isSelected ? "transparent" : "#bfbfbf",
                                                                                },
                                                                            }}
                                                                        >
                                                                            {item.value}
                                                                        </Button>

                                                                        {/* Offer price for non-size addons */}
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={1}
                                                                            alignItems="center"
                                                                        >
                                                                            <Typography
                                                                                variant="caption"
                                                                                sx={{ color: "success.main", fontWeight: "bold" }}
                                                                            >
                                                                                {`+₹${item.offerPrice ? item.offerPrice : 0}`}
                                                                            </Typography>
                                                                        </Stack>

                                                                    </>
                                                                )}
                                                            </Stack>
                                                        );
                                                    })}
                                                </Stack>
                                            </Box>
                                        </Stack>
                                        {index < productInfo.addons.length - 1 && <Divider sx={{ marginY: 2 }} />}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    )
                    }

                    {/* Price and Actions */}
                    <Box sx={{ paddingTop: 2, borderTop: "1px solid #ddd" }}>
                        <Stack direction="row" alignItems="center" justifyContent={'space-between'} mb={2}>
                            <Box>
                                <Stack direction={'row'} alignItems="center" spacing={1}>
                                    <Box sx={{ fontSize: "25px", fontWeight: 'medium', color: '#002f49' }}>
                                        ₹{totalOfferPrice?.toFixed(2)}
                                    </Box>
                                    <Box
                                        sx={{
                                            textDecoration: "line-through",
                                            color: "#888",
                                        }}
                                    >
                                        ₹{totalMrp?.toFixed(2)}
                                    </Box>
                                    <Box
                                        sx={{
                                            color: "primary.main",
                                            padding: "1px 5px",
                                            border: (theme) =>
                                                `1px solid ${theme.palette.primary.main}`,
                                            borderRadius: "8px",
                                            textAlign: "center",
                                            display: "inline-block",
                                            marginLeft: "5px",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {Math.ceil(calculateDiscountPercent(totalMrp, totalOfferPrice))}% off
                                    </Box>
                                </Stack>
                            </Box>

                            <Box>
                                <Stack direction={"row"} spacing={1}>
                                    <Remove
                                        onClick={() => handleChangeQuantity(-1)}
                                        className="cursor-p"
                                        style={{
                                            fontSize: "2rem",
                                            color: "#fff",
                                            backgroundColor: "#002f49",
                                            borderRadius: "50px",
                                        }}
                                    />
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        sx={{
                                            width: "30px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography sx={{ fontSize: 22 }}>{quantity}</Typography>
                                    </Stack>
                                    <Add
                                        onClick={() => handleChangeQuantity(1)}
                                        className="cursor-p"
                                        style={{
                                            fontSize: "2rem",
                                            color: "#fff",
                                            backgroundColor: "#002f49",
                                            borderRadius: "50px",
                                        }}
                                    />
                                </Stack>
                            </Box>
                        </Stack>

                        <Box mt={1} sx={{ margin: 'auto' }}>
                            <Button
                                onClick={() => handleAddProductToCartWithAddons(productInfo)}
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: "100%",
                                    borderRadius: "50px",
                                    fontWeight: "bold",
                                    bgcolor: "#002f49",
                                    color: "#fff",
                                    "&:hover": { bgcolor: "#002f49" },
                                }}
                            >
                                ADD
                            </Button>
                        </Box>
                    </Box>
                </Drawer>
            </Box>
        </Box >
    );
};


export function CategoryFilterChip({ filterText, isSelected, onClick, clickable = true }) {
    return (
        <Chip
            label={filterText}
            onClick={clickable ? onClick : undefined}
            variant={isSelected ? 'default' : 'outlined'}
            className={isSelected ? 'clickedCategoryChip' : 'unclickedCategoryChip'}
            clickable={clickable}
            sx={{
                backgroundColor: isSelected ? '#002f49' : 'white',
                borderColor: isSelected ? '#002f49' : 'default',
                color: isSelected ? 'white' : 'default',
            }}
        />
    );
}


export default Products;


import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Add, Remove } from "@mui/icons-material";
import axios from "../api/axios";
import PrimaryButton from "../components/PrimaryButton";
import { loadScript } from "../utils/razorpay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CancelIcon from '@mui/icons-material/Cancel';

const OrderPayment = () => {
    const [storedProducts, setStoredProducts] = useState([]);
    const outlet = localStorage.getItem("outlet");
    const kioskUser = localStorage.getItem("kioskUser");
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [totalAmount, setTotalAmount] = useState(0);
    const [additionalCharges, setAdditionalCharges] = useState({});
    const [userDetails, setUserDetails] = useState(null);
    const [makingTime, setMakingTime] = useState("");
    const [makingTimeInMinutes, setMakingTimeInMinutes] = useState("")

    const updateCartItems = (productId, newQuantity) => {
        // Ensure newQuantity is not less than 1
        if (newQuantity < 1) return;

        const updatedItems = cartItems.map((item) =>
            item._id === productId ? { ...item, quantity: newQuantity } : item
        );

        // Add the item if it's not found in the updatedItems
        if (!updatedItems.find((item) => item._id === productId)) {
            updatedItems.push({ _id: productId, quantity: newQuantity });
        }

        setCartItems(updatedItems);
        return updatedItems;
    };

    const handleQuantityChange = async (productId, newQuantity) => {
        try {
            if (newQuantity < 1) return;
            const token = localStorage.getItem("authToken");

            // Update cart items locally
            const updatedCartItems = updateCartItems(productId, newQuantity);

            // Prepare payload
            const payload = {
                cartItems: updatedCartItems,
            };

            // Send update request to the server
            const response = await axios.put("cart", payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.status === 200) {
                getCartItems();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getCartItems = async () => {
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(`cart/userCart/${outlet}/PICKUP`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.data?.status === true) {
                const totalAmount = response?.data?.data?.cart?.grandTotal;
                setAdditionalCharges(response?.data?.data?.additionalCharges);
                setTotalAmount(totalAmount);
                setCartItems(response?.data?.data?.cartItems);
                localStorage.setItem(
                    "cartItems",
                    JSON.stringify(response?.data?.data?.cartItems)
                );
                getCartReadyTime()
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handlePayment = async () => {
        const order_id = localStorage.getItem("orderId");
        const amount = totalAmount;
        const userPhone = kioskUser?.phone || '';

        const options = {
            key: process.env.RPAY_KEY,
            amount: amount,
            currency: "INR",
            order_id: order_id,
            handler: function (response) {
                handlePaymentVerification(response);
            },
            prefill: {
                name: "Xpanse",
                contact: userPhone,
            },
            notes: {
                address: "123, Street Name, City, Country",
            },
            theme: {
                // color: orderData?.configuration?.theme?.color,
            },
            // modal: {
            //     ondismiss: function (response) {
            //         // handlePaymentFailure(orderDetails?.order_id)
            //     }
            // }
        };

        localStorage.removeItem("cart_id");

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    };

    const handlePaymentVerification = async (razorpay) => {
        try {
            const authToken = localStorage.getItem("authToken");
            const kioskUser = JSON.parse(localStorage.getItem("kioskUser"));

            if (!authToken) {
                throw new Error("Authentication token is missing.");
            }

            const headers = {
                Authorization: `Bearer ${authToken}`,
            };

            const payload = {
                razorpayOrderId: razorpay?.razorpay_order_id,
                razorpayPaymentId: razorpay?.razorpay_payment_id,
                razorpaySignature: razorpay?.razorpay_signature,
                paymentMode: "ONLINE",
                outlet: outlet,
                readyTime: makingTime,
                arrivalTimeDurationInMinutes: makingTimeInMinutes,
                kioskUser: kioskUser?._id,
            };

            const response = await axios.post("order/verifyPayment", payload, {
                headers,
            });

            if (response?.status === 201) {
                navigate("/order-confirmation");
            }
        } catch (error) {
            if (error.response && error.response.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            } else {
                toast.error("An unexpected error occurred.", {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const deleteCartItem = async (id) => {
        try {
            const token = localStorage.getItem("authToken");

            const payload = {
                cartItemIds: [id],
            };

            const response = await axios.delete("cart", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: payload,
            });

            if (response?.status === 200) {
                const message = response?.data?.data?.message;
                toast.success(message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                getCartItems();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const navigateToProductPage = () => {
        navigate('/products')
    }

    const getCartReadyTime = async () => {
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(`cart/getMakingTIme`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.data?.status === true) {
                const readyTime = response?.data?.data?.readyTime
                localStorage.setItem('cartReadyTime', readyTime)
                setMakingTime(response?.data?.data?.readyTime);
                const time = response?.data?.data?.makingTimeDuration.split(" ")[0]
                setMakingTimeInMinutes(time)
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        const storedProducts =
            JSON.parse(localStorage.getItem("selectedProducts")) || [];
        setStoredProducts(storedProducts);

        const kioskUser = JSON.parse(localStorage.getItem("kioskUser"));
        setUserDetails(kioskUser);
        getCartItems();
    }, []);

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js")
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error loading Razorpay script:", error);
            });
    }, []);

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'row', background: '#f1f3f4', }}>
            {/* Arrow Box */}
            <Box
                sx={{
                    width: "5%",
                    display: 'flex',
                    justifyContent: 'center',
                    bgcolor: '#f1f3f4',
                    position: 'sticky',
                    top: 0,
                    height: '100vh',
                }}
            >
                <KeyboardBackspaceIcon
                    sx={{ color: "black", fontSize: 30, background: '#e7e9eb', padding: '4px', borderRadius: '50%', cursor: 'pointer', marginTop: '10px' }}
                    onClick={navigateToProductPage}
                />
            </Box>

            {/* Cart Items Box */}
            <Box
                sx={{
                    width: "43%",
                    overflowY: "auto",
                    bgcolor: '#f1f3f4',
                    height: '100vh',
                    marginRight: '2%',
                    marginTop: '10px'

                }}
            >
                {cartItems && cartItems.length > 0 && (
                    <Box>
                        <Typography textAlign={"left"} variant="h6">
                            {userDetails?.name || "User Name"}
                        </Typography>
                        <Typography
                            fontWeight={"default"}
                            textAlign={"left"}
                            variant="body2"
                        >
                            {userDetails?.email || "User Email"}
                        </Typography>
                        <Typography
                            fontWeight={"default"}
                            textAlign={"left"}
                            variant="body2"
                        >
                            {userDetails?.phone || "User Phone"}
                        </Typography>
                        <Typography
                            fontWeight={"default"}
                            textAlign={"left"}
                            variant="body2"
                        >
                            ARRIVAL TIME : {makingTime}
                        </Typography>
                        <Box>
                            {cartItems?.map((item) => (
                                <Grid container key={item._id} justifyContent="space-between">
                                    <Grid
                                        item
                                        md={12}
                                        className="shadowed-box"
                                        sx={{
                                            borderRadius: "10px",
                                            padding: "10px",
                                            backgroundColor: "#fff",
                                            margin: "10px 0",
                                        }}
                                    >
                                        <Grid container sx={{ padding: "4px 0" }}>
                                            <Grid
                                                item
                                                md={11}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    fontWeight="bold"
                                                >
                                                    {item?.product.name} - ₹ {item?.taxable?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            {item?.addons &&
                                                item?.addons.map((addon) => (
                                                    <Grid item key={addon._id} className="attributes">
                                                        {addon?.selectedValue}
                                                    </Grid>
                                                ))}
                                        </Grid>
                                        <Stack
                                            direction="row"
                                            justifyContent={"space-between"}
                                            sx={{ padding: "20px 0 10px 0", alignItems: "center" }}
                                        >
                                            <CancelIcon
                                                sx={{ color: "#bcbcbd", fontSize: 20 }}
                                                className="cursor-p"
                                                onClick={() => deleteCartItem(item?._id)}
                                            />
                                            <Box>
                                                <Stack direction={"row"} spacing={1}>
                                                    <Remove
                                                        onClick={() =>
                                                            handleQuantityChange(item?._id, item?.quantity - 1)
                                                        }
                                                        className="cursor-p"
                                                        style={{
                                                            fontSize: "2rem",
                                                            color: "#fff",
                                                            backgroundColor: "#002f49",
                                                            borderRadius: "50px",
                                                        }}
                                                    />
                                                    <Stack
                                                        direction={"row"}
                                                        alignItems={"center"}
                                                        justifyContent={"center"}
                                                        sx={{ width: "30px", textAlign: "center" }}
                                                    >
                                                        {item?.quantity}
                                                    </Stack>
                                                    <Add
                                                        onClick={() =>
                                                            handleQuantityChange(item?._id, item?.quantity + 1)
                                                        }
                                                        className="cursor-p"
                                                        style={{
                                                            fontSize: "2rem",
                                                            color: "#fff",
                                                            backgroundColor: "#002f49",
                                                            borderRadius: "50px",
                                                        }}
                                                    />
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>

            {/* Payment Box */}
            <Box
                sx={{
                    width: "50%",
                    bgcolor: 'white',
                    height: '100vh', // Full height
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Card sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <CardHeader
                        sx={{ borderBottom: "1px solid #cecece" }}
                        title={<Typography variant="h6">Payment</Typography>}
                    />
                    <CardContent sx={{ flex: 1 }}>
                        <Typography>Order Summary</Typography>
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item sx={{ textAlign: "left" }}>
                                <Typography variant="subtitle1" color="text.primary">Item(s)</Typography>
                                <Typography variant="subtitle1" color="text.primary">Total MRP</Typography>
                                <Typography variant="subtitle1" color="text.primary">Total Tax Price</Typography>
                                <Typography variant="subtitle1" color="text.primary">Additional Charges</Typography>
                                <Typography variant="subtitle1" color="text.primary">Discounts</Typography>
                            </Grid>
                            <Grid item sx={{ textAlign: "right" }}>
                                <Typography variant="subtitle1" color="text.secondary">
                                    {cartItems?.reduce((acc, item) => acc + item?.quantity, 0)}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    ₹ {cartItems.reduce((acc, product) => acc + product.taxable, 0).toFixed(2)}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    ₹ {cartItems.reduce((acc, product) => acc + product.taxAmount, 0).toFixed(2)}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    ₹ {((additionalCharges?.platformFees || 0) + (additionalCharges?.GSTPlatformFees || 0)).toFixed(2)}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">FREE!</Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item>
                                <Typography variant="h5">Total Payable</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">
                                    ₹ {(
                                        cartItems.reduce((acc, product) => acc + (product.grandTotal || 0), 0) +
                                        (additionalCharges?.platformFees || 0) +
                                        (additionalCharges?.GSTPlatformFees || 0)
                                    ).toFixed(2)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <PrimaryButton
                            title={"PAY NOW"}
                            onClick={handlePayment}
                            spinner={true}
                            disabledTitle={"Logging In"}
                            style={{
                                borderRadius: "30px", fontSize: "18px",
                                width: '90%',
                                marginLeft: '10%',
                                marginTop: '10px',
                                marginBottom: '20px',
                                background: '#002f49'
                            }}
                        />
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default OrderPayment;

import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const OrderConfirmation = () => {
    const navigate = useNavigate();
    const cartId = localStorage.getItem('cartId');
    const orderAmount = localStorage.getItem('orderAmount');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const outlet = localStorage.getItem('outlet')
    const cartReadyTime = localStorage.getItem('cartReadyTime')

    const products = Array.isArray(selectedProducts) ? selectedProducts : [];

    const navigateToUserDetails = () => {
        navigate('/user-details');
    }

    useEffect(() => {
        const products = JSON.parse(localStorage.getItem('cartItems')) || [];
        setSelectedProducts(products);
    }, [])

    return (
        <Box sx={{ padding: 3, backgroundColor: '#FAEBD7', minHeight: '100vh', background: "white" }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 3 }}>
                Your Order Has Been Placed Successfully!
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {/* Item Summary Section */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={2} sx={{ padding: 2, borderRadius: 2 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                            Product Overview
                        </Typography>
                        <Divider />
                        {/* Repeatable item block */}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: '1.1rem' }}>Product Name</TableCell>
                                    <TableCell sx={{ fontSize: '1.1rem', textAlign: 'center', }}>Quantity</TableCell>
                                    <TableCell sx={{ textAlign: 'right', fontSize: '1.1rem', }}>Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products && products.length > 0 && products.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ fontSize: '1.1rem' }}>
                                            {item?.product.name}
                                            <Grid container>
                                                {item?.addons && item?.addons.map((addon, addonIndex) => (
                                                    <Grid item key={addonIndex} className="attributes">
                                                        {addon?.selectedValue}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </TableCell >
                                        <TableCell sx={{ fontSize: '1.1rem', textAlign: 'center', }}>{item?.quantity}</TableCell>
                                        <TableCell
                                            sx={{
                                                color: '#002f49',
                                                fontWeight: 'bold',
                                                fontSize: '1.1rem',
                                                textAlign: 'right',
                                            }}
                                        >₹ {item?.grandTotal?.toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* Pickup Details */}
                        <Box
                            sx={{
                                marginTop: 2,
                                padding: 2,
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#333',
                                    marginBottom: 1,
                                }}
                            >
                                Pickup
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: '#555',
                                }}
                            >
                                Estimated Ready Time:
                                <Typography
                                    component="span"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#d32f2f',
                                        marginLeft: '4px',
                                    }}
                                >
                                    {cartReadyTime}
                                </Typography>
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>

                {/* Order Summary Section */}
                <Grid item xs={12} md={4}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: 3,
                            borderRadius: 2,
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                color: '#333',
                                marginBottom: 2,
                                textAlign: 'center',
                            }}
                        >
                            Order Summary
                        </Typography>
                        <Divider sx={{ marginBottom: 3 }} />
                        {/* Order Summary Details */}
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 2,
                                }}
                            >
                                <Typography variant="body1" sx={{ color: '#555' }}>
                                    Item(s)
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#333',
                                    }}
                                >
                                    {products?.reduce((acc, item) => acc + item?.quantity, 0)}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 2,
                                }}
                            >
                                <Typography variant="body1" sx={{ color: '#555' }}>
                                    Total MRP
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#333',
                                    }}
                                >
                                    ₹ {(orderAmount / 100).toFixed(2)}
                                </Typography>
                            </Box>
                            <Divider sx={{ marginY: 2 }} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    fontWeight: 'bold',
                                }}
                            >
                                <Typography variant="h6" sx={{ color: '#444' }}>
                                    Grand Total
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#d32f2f',
                                    }}
                                >
                                    ₹ {(orderAmount / 100).toFixed(2)}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ marginY: 2 }} />
                    </Paper>
                </Grid>

            </Grid>

            <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 3, marginTop: '100px' }}>
                <Button variant='contained' onClick={navigateToUserDetails} sx={{
                    background: '#002f49', borderRadius: "30px", fontSize: "18px",
                }}>
                    {/* Redirecting in {seconds} seconds... */}
                    Continue Shopping...
                </Button>
            </Typography>
        </Box>
    )
}

export default OrderConfirmation

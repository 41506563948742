import React, { useEffect, useState } from "react";
import {
    Box,
    Toolbar,
    Typography,
    TextField,
    Grid,
    IconButton,
    InputAdornment,
    Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import PrimaryButton from "../components/PrimaryButton";
import axios from "../api/axios";
import { setCashierDetails } from "../state/cashierSlice";

const CashierLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cashierData, setCashierData] = useState({
        username: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation();
    const message = location?.state?.message;

    useEffect(() => {
        if (message === true) {
            navigate(location.pathname, { replace: true, state: {} });
            window.location.reload();
        }
    }, [navigate, location]);

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCashierData((prev) => ({ ...prev, [name]: value }));
    };

    const validate = () => {
        const newErrors = {};
        let isValid = true;

        // Mobile Validation
        if (!cashierData.username.trim()) {
            newErrors.mobile = "Username is required";
            isValid = false;
        }

        // OTP Validation
        if (!cashierData.password.trim()) {
            newErrors.password = "Password is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleLogin = async () => {
        try {
            const response = await axios.post(
                "auth/user/kiosk-outlet-user-signin",
                {
                    username: cashierData.username,
                    password: cashierData.password,
                }
            );
            if (response.status === 200) {
                localStorage.setItem("authToken", response?.data?.data?.jwt);
                localStorage.setItem(
                    "outlet",
                    response?.data?.data?.user?.outlet?._id
                );
                const message = response?.data?.data?.message;
                toast.success(message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                navigate("/user-details");
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    return (
        <>
            <Box sx={{ textAlign: "center", background: "white" }}>
                <Grid container justifyContent="center">
                    <Grid item md={4}>
                        <Toolbar />
                        <Toolbar />
                        {/* <img src={Images.xpanse} /> */}
                        <Typography sx={{ fontSize: "22px", margin: "0px 0 18px 0" }}>
                            KIOSK Login
                        </Typography>
                        <Box sx={{ textAlign: "left" }}>
                            <label style={{}}>Username</label>
                            <TextField
                                fullWidth
                                name="username"
                                value={cashierData.username}
                                id="cashier"
                                sx={{ borderRadius: "8px", backgroundColor: "#fff" }}
                                placeholder="Enter Username"
                                variant="outlined"
                                onChange={handleInputChange}
                            />
                            <p style={{ marginTop: "-1px", fontSize: "12px", color: "red" }}>
                                {errors.username}
                            </p>
                        </Box>
                        <Box sx={{ textAlign: "left", marginTop: "21px" }}>
                            <label style={{}}>Password</label>
                            <TextField
                                fullWidth
                                name="password"
                                sx={{ borderRadius: "8px", backgroundColor: "#fff" }}
                                placeholder="Enter Password"
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                value={cashierData.password}
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handlePasswordVisibility} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <p style={{ marginTop: "-1px", fontSize: "12px", color: "red" }}>
                                {errors.password}
                            </p>
                        </Box>

                        <Grid
                            container
                            justifyContent={"center"}
                            style={{ marginTop: "21px" }}
                        >
                            <Grid item sm={12} md={12}>
                                <PrimaryButton
                                    title={"Proceed"}
                                    onClick={handleLogin}
                                    disabled={btnDisabled}
                                    spinner={true}
                                    disabledTitle={"Logging In"}
                                    style={{
                                        borderRadius: "30px",
                                        fontSize: "18px",
                                        width: "100%",
                                        marginTop: "10px",
                                        marginBottom: "20px",
                                        background: "#002f49",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default CashierLogin;

import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Hidden, IconButton, } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from '../api/axios';

const drawerWidth = 130;

export default function NavBar() {
    const [currentDateTime, setCurrentDateTime] = React.useState(getCurrentDateTime());
    const navigate = useNavigate()

    const pathname = window.location.pathname;

    function getCurrentDateTime() {
        const now = new Date();

        const dayOptions = { weekday: 'long' };
        const dayOfWeek = now.toLocaleDateString('en-US', dayOptions);

        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = now.toLocaleDateString('en-US', dateOptions);

        const timeOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
        const time = now.toLocaleTimeString('en-US', timeOptions);

        return `${date} ${dayOfWeek} | ${time}`;
    }

    const navigateToHome = () => {
        navigate('/user-details')
    }

    const navigateToLogin = () => {
        navigate('/')
    }

    const deleteCartItem = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const cartId = localStorage.getItem("cartId");

            const payload = {
                cartId,
            };

            const response = await axios.delete("cart", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: payload,
            });

            if (response?.status === 200) {
                // getCartItems();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("cartItems");
        localStorage.removeItem("kioskUser");
        localStorage.removeItem("orderAmount");
        localStorage.removeItem("orderId");
        navigateToHome()
    }

    const handleOutletUserLogout = () => {
        localStorage.removeItem("kioskUser");
        navigateToLogin()
    }

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(getCurrentDateTime());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <CssBaseline />
            <Box onClick={navigateToHome} position="fixed" className='loginLogo' sx={{ width: `calc(${drawerWidth}px)`, zIndex: '10000' }}>
                <Typography className='head' sx={{ color: '#fff' }} >
                    Xpanse
                </Typography>
                <Typography className='para' sx={{ color: '#fff' }}>
                    KIOSK
                </Typography>
            </Box>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`,
                    height: '90px',
                    background: '#003049',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    boxShadow: '0',
                }}
            >
                <Hidden mdDown>
                    <Toolbar>
                        <Typography sx={{ width: '420px' }} variant="h6" noWrap component="div">
                            {currentDateTime}
                        </Typography>
                    </Toolbar>
                </Hidden>
                {
                    pathname !== '/' && pathname !== '/user-details' ? <Toolbar>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleLogout}
                            sx={{ ml: 'auto' }}
                        >
                            <PowerSettingsNewIcon />
                        </IconButton>
                    </Toolbar> : null
                }

                {
                    pathname === '/user-details' ? <Toolbar>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleOutletUserLogout}
                            sx={{ ml: 'auto' }}
                        >
                            <PowerSettingsNewIcon />
                        </IconButton>
                    </Toolbar> : null
                }

            </AppBar>
        </>
    );
}